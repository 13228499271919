import React from "react";

export default function CTA() {
  return (
    <div className="bg-art-beige rounded-3xl">
      <div className=" mx-auto max-w-7xl px-6 py-8 sm:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
        <p className="text-normal tracking-tight text-gray-900 sm:text-normal">
          Besoin de matériel pour votre déménagement ? <br />
          Nous vous proposons de commander par formulaire ou au 04 72 32 57 57.{" "}
          <br />
          Les produits choisis pourront être récupérés dans l'agence de
          Tassin-la-Demi-Lune.
        </p>
        <div className="lg:ml-8 mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <a
            href="/commande/"
            className="inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-base font-medium !text-white !no-underline bg-art-orange hover:bg-art-bleu"
          >
            Commander
          </a>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

import Header from "src/components/headers/header";
import Ariane from "src/components/headers/ariane";
import Footer from "src/components/footers/footer";

import Engagement from "../components/shortcodes/Engagement";
import Cta from "../components/shortcodes/Cta";
import Presse from "../components/shortcodes/Presse";
import Prix from "../components/shortcodes/Prix";
import CtaCommande from "../components/shortcodes/CtaCommande";

const shortcodes = { Engagement, Cta, Presse, Prix, CtaCommande };

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-xl">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <Ariane fil_ariane={fil_ariane} />
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-gray-50 overflow-y-auto paragraph">
                <div className="relative bg-gray-50 overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-4">
                    <div className="text-lg mx-auto">
                      <h1>
                        <span className="mt-8 mb-4 block text-4xl text-left leading-8 font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-6xl">
                          {title}
                        </span>
                      </h1>
                      {/* <p className="mt-8 mb-8 text-xl text-art-marron-fonce leading-8">
                            {excerpt}
                          </p> */}
                    </div>

                    <div className="mx-auto max-w-2xl pb-8 pt-8 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pb-8 lg:pt-8">
                      <div className="col-span-1">
                        <div className="rounded-3xl ">
                          <GatsbyImage
                            className="rounded-3xl"
                            alt={thumbnailText}
                            image={getImage(embeddedImagesLocal[0])}
                          />
                        </div>
                        <div className="mt-8 text-xl flex-none text-art-orange">
                          Nos catégories
                        </div>
                        <ul
                          className="mt-6 space-y-3 text-xl leading-6 text-gray-600"
                        >
                          <li className="flex gap-x-3">
                            <ArrowCircleRightIcon
                              className="h-6 w-6 flex-none text-art-orange"
                              aria-hidden="true"
                            />
                            <a href="/boutique/cartons/">Cartons</a>
                          </li>
                          <li className="flex gap-x-3">
                            <ArrowCircleRightIcon
                              className="h-6 w-6 flex-none text-art-orange"
                              aria-hidden="true"
                            />
                            <a href="/boutique/accessoires-emballage/">
                              Accessoires d'emballage
                            </a>
                          </li>
                          <li className="flex gap-x-3">
                            <ArrowCircleRightIcon
                              className="h-6 w-6 flex-none text-art-orange"
                              aria-hidden="true"
                            />
                            <a href="/boutique/manutention-transport/">
                              Manutention et transport
                            </a>
                          </li>
                          <li className="flex gap-x-3">
                            <ArrowCircleRightIcon
                              className="h-6 w-6 flex-none text-art-orange"
                              aria-hidden="true"
                            />
                            <a href="/boutique/kit/">Kits</a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-span-2 prose-h2:my-4 prose-h2:text-3xl prose-h3:text-2xl max-w-none prose-a:text-art-bleu prose-headings:text-art-marron-fonce prose prose-blue prose-lg text-art-marron-fonce mx-auto">
                        <MDXProvider components={shortcodes}>
                          <MDXRenderer
                            className="mt-6 prose prose-lg text-art-marron-fonce mx-auto"
                            remoteImages={embeddedImagesRemote}
                            localImages={embeddedImagesLocal}
                          >
                            {body}
                          </MDXRenderer>
                        </MDXProvider>
                      </div>
                    </div>

                    <div className="text-lg mx-auto">
                      <div className="mx-auto max-w-2xl py-4 lg:max-w-7xl">
                        <h2 className="text-3xl font-bold tracking-tight text-art-marron-fonce">
                          Voir nos kits
                        </h2>

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          <div className="group relative">
                            <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                              <div className="object-contain  rounded-3xl  object-center ">
                                <StaticImage
                                  className="rounded-3xl  object-center"
                                  alt="Kit classique"
                                  src="../markdown-pages/kit-classique.jpg"
                                />
                              </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                              <div>
                                <h3 className="text-normal text-art-marron-fonce">
                                  <a href="/boutique/kit/kit-classique/">
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    Kit classique
                                  </a>
                                </h3>
                                {/* <p className="mt-1 text-sm text-gray-500">
                                    {product.color}
                                  </p> */}
                              </div>
                              <p className="text-normal font-medium text-art-orange">
                                95,95€ TTC
                              </p>
                            </div>
                          </div>

                          <div className="group relative">
                            <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                              <div className="object-contain  rounded-3xl  object-center ">
                                <StaticImage
                                  className="rounded-3xl  object-center"
                                  alt="Kit confort"
                                  src="../markdown-pages/kit-confort.jpg"
                                />
                              </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                              <div>
                                <h3 className="text-normal text-art-marron-fonce">
                                  <a href="/boutique/kit/kit-confort/">
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    Kit confort
                                  </a>
                                </h3>
                                {/* <p className="mt-1 text-sm text-gray-500">
                                    {product.color}
                                  </p> */}
                              </div>
                              <p className="text-normal font-medium text-art-orange">
                                123,92€ TTC
                              </p>
                            </div>
                          </div>

                          <div className="group relative">
                            <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                              <div className="object-contain  rounded-3xl  object-center ">
                                <StaticImage
                                  className="rounded-3xl  object-center"
                                  alt="Kit confort plus"
                                  src="../markdown-pages/kit-confort-plus.jpg"
                                />
                              </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                              <div>
                                <h3 className="text-normal text-art-marron-fonce">
                                  <a href="/boutique/kit/kit-confort-plus/">
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    Kit confort plus
                                  </a>
                                </h3>
                                {/* <p className="mt-1 text-sm text-gray-500">
          {product.color}
        </p> */}
                              </div>
                              <p className="text-normal font-medium text-art-orange">
                                192,92€ TTC
                              </p>
                            </div>
                          </div>

                          <div className="group relative">
                            <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                              <div className="object-contain  rounded-3xl  object-center ">
                                <StaticImage
                                  className="rounded-3xl  object-center"
                                  alt="Kit grand confort"
                                  src="../markdown-pages/kit-grand-confort.jpg"
                                />
                              </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                              <div>
                                <h3 className="text-normal text-art-marron-fonce">
                                  <a href="/boutique/kit/kit-grand-confort/">
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    Kit grand confort
                                  </a>
                                </h3>
                                {/* <p className="mt-1 text-sm text-gray-500">
          {product.color}
        </p> */}
                              </div>
                              <p className="text-normal font-medium text-art-orange">
                                243,80€ TTC
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;

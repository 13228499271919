import React from "react";

const Prix = ({ prix, prix_quantite, prix_barre }) => (
  <div className="text-2xl lg:text-4xl text-art-orange">
    {prix}
    {prix_barre ? (
      <span className="text-xl lg:text-2xl text-art-marron-fonce line-through">
        {" "}
        {prix_barre}
      </span>
    ) : (
      ""
    )}
    {prix_quantite ? (
      <span className="text-xl lg:text-2xl text-art-marron-fonce">
        {" "}
        ({prix_quantite})
      </span>
    ) : (
      ""
    )}
  </div>
);

export default Prix;
